<template>
  <div></div>
</template>

<script setup lang="ts">
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

const { locale } = useI18n()
const router = useRouter()
const { isAuthed, signIn } = useAutobidAuth()

const init = () => {
  if (isAuthed.value) {
    router.push({ path: `/${locale.value}` })
  } else {
    signIn()
  }
}

onMounted(() => {
  init()
})
</script>
